<script lang="ts" setup>
import { Toaster as Sonner, type ToasterProps } from 'vue-sonner';
import { Icon } from '@iconify/vue';

const props = defineProps<ToasterProps>()
</script>

<template>
  <Sonner
    class="toaster group [&>li]:bg-popover [&>li]:text-popover-foreground [&>li]:border-border [&>li]:items-start [&>li]:p-5"
    v-bind="props"
    close-button
    :toast-options="{ 
      classes: {
        title: 'text-sm font-semibold',
        closeButton: '!bg-transparent !text-white !border-0 !transform-none !top-4 !right-4 !justify-self-end',
      } 
    }"
  >
    <template #close-icon>
      <Icon class="size-4.5" icon="carbon:close" />
    </template>
    <template #success-icon>
      <Icon class="size-4.5 text-green-500" icon="carbon:checkmark-outline" />
    </template>
    <template #info-icon>
      <Icon class="size-4.5 text-blue-500" icon="carbon:warning" />
    </template>
    <template #warning-icon>
      <Icon class="size-4.5 text-yellow-500" icon="carbon:warning-alt" />
    </template>
    <template #error-icon>
      <Icon class="size-4.5 text-red-500" icon="carbon:close-outline" />
    </template>
  </Sonner>
</template>
